.q-field--outlined .q-field__control {
  border-radius: 4px;
}

.grid-masonry {
  flex-direction: column;
  height: 1500px;
}
.grid-masonry--2 > div:nth-child(2n+1) {
  order: 1;
}
.grid-masonry--2 > div:nth-child(2n) {
  order: 2;
}
.grid-masonry--2:before {
  content: "";
  flex: 1 0 100% !important;
  width: 0 !important;
  order: 1;
}
.grid-masonry--3 > div:nth-child(3n+1) {
  order: 1;
}
.grid-masonry--3 > div:nth-child(3n+2) {
  order: 2;
}
.grid-masonry--3 > div:nth-child(3n) {
  order: 3;
}
.grid-masonry--3:before, .grid-masonry--3:after {
  content: "";
  flex: 1 0 100% !important;
  width: 0 !important;
  order: 2;
}
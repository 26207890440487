@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
// app global css in Sass form
.q-field--outlined .q-field__control
  border-radius: $editor-border-radius

.grid-masonry
  flex-direction: column
  height: 1500px

  &--2
    > div
      &:nth-child(2n + 1)
        order: 1
      &:nth-child(2n)
        order: 2

    &:before
      content: ''
      flex: 1 0 100% !important
      width: 0 !important
      order: 1
  &--3
    > div
      &:nth-child(3n + 1)
        order: 1
      &:nth-child(3n + 2)
        order: 2
      &:nth-child(3n)
        order: 3

    &:before,
    &:after
      content: ''
      flex: 1 0 100% !important
      width: 0 !important
      order: 2
